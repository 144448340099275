import React, { useState } from "react";
import { Space, Table, Tag } from "antd";
import { Icon } from "@iconify/react/dist/iconify.js";

import { Link, NavLink, useNavigate } from "react-router-dom";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import redTrash from "../../../assets/icons/redTrash.svg";

const data = [
  {
    key: "1",
    date: "30-Jun-24",
    plan: "Basic Plan Renewal",
    amount: "$99.00",
    status: "Complete",
    subscription: "Monthly",
    endDate: "30-Jul-24",
  },
  {
    key: "2",
    date: "30-May-24",
    plan: "Pro Plan Renewal",
    amount: "$99.00",
    status: "Complete",
    subscription: "Monthly",
    endDate: "30-Jul-24",
  },
  {
    key: "3",
    date: "30-May-24",
    plan: "Basic Plan Renewal",
    amount: "$99.00",
    status: "Complete",
    subscription: "Monthly",
    endDate: "30-Jul-24",
  },
  {
    key: "4",
    date: "30-Dec-24",
    plan: "Pro Plan Renewal",
    amount: "$99.00",
    status: "Complete",
    subscription: "Monthly",
    endDate: "30-Jul-24",
  },

  {
    key: "5",
    date: "30-Nov-24",
    plan: "Basic Plan Renewal",
    amount: "$99.00",
    status: "Complete",
    subscription: "Monthly",
    endDate: "30-Jul-24",
  },

  {
    key: "6",
    date: "30-Feb-24",
    plan: "Basic Plan Renewal",
    amount: "$99.00",
    status: "Complete",
    subscription: "Monthly",
    endDate: "30-Jul-24",
  },
  {
    key: "7",
    date: "30-Feb-24",
    plan: "Basic Plan Renewal",
    amount: "$99.00",
    status: "Complete",
    subscription: "Monthly",
    endDate: "30-Jul-24",
  },
  {
    key: "8",
    date: "30-Feb-24",
    plan: "Basic Plan Renewal",
    amount: "$99.00",
    status: "Complete",
    subscription: "Monthly",
    endDate: "30-Jul-24",
  },
  {
    key: "9",
    date: "30-Feb-24",
    plan: "Basic Plan Renewal",
    amount: "$99.00",
    status: "Complete",
    subscription: "Monthly",
    endDate: "30-Jul-24",
  },
  {
    key: "10",
    date: "30-Feb-24",
    plan: "Basic Plan Renewal",
    amount: "$99.00",
    status: "Complete",
    subscription: "Monthly",
    endDate: "30-Jul-24",
  },
  {
    key: "11",
    date: "30-Feb-24",
    plan: "Basic Plan Renewal",
    amount: "$99.00",
    status: "Complete",
    subscription: "Monthly",
    endDate: "30-Jul-24",
  },
  {
    key: "12",
    date: "30-Feb-24",
    plan: "Basic Plan Renewal",
    amount: "$99.00",
    status: "Complete",
    subscription: "Monthly",
    endDate: "30-Jul-24",
  },
  {
    key: "13",
    date: "30-Feb-24",
    plan: "Basic Plan Renewal",
    amount: "$99.00",
    status: "Complete",
    subscription: "Monthly",
    endDate: "30-Jul-24",
  },
  {
    key: "14",
    date: "30-Feb-24",
    plan: "Basic Plan Renewal",
    amount: "$99.00",
    status: "Complete",
    subscription: "Monthly",
    endDate: "30-Jul-24",
  },
  {
    key: "15",
    date: "30-Feb-24",
    plan: "Basic Plan Renewal",
    amount: "$99.00",
    status: "Complete",
    subscription: "Monthly",
    endDate: "30-Jul-24",
  },
];
const Bill = () => {
  const { Column } = Table;
  return (
    <>
      <div className="billContainer">
        <p className="fw-semibold">Current Plan</p>
        <div className="billStatment d-flex gap-2 mt-2">
          <p className="dark-bluish fw-normal">Pro Plan</p>
          <p className="text-small parrot-green fw-medium">Active</p>
        </div>
        <div className="currentDta">
          {" "}
          <p className="fw-normal">July 30, 2024</p>
        </div>

        <div>
          <div className="tableSection pt-4">
            <div className="tableWrapper">
              <Table
                dataSource={data}
                pagination={{
                  pageSize: 6, // Number of items per page
                  showSizeChanger: false,
                  itemRender: (page, type, originalElement) => {
                    if (type === "prev") {
                      return <LeftOutlined />;
                    }
                    if (type === "next") {
                      return <RightOutlined />;
                    }
                    if (page > 5) {
                      return <span>...</span>;
                    }
                    return originalElement;
                  },
                  position: ["bottomCenter"],
                }}
              >
                <Column title="Date" dataIndex="date" key="date" />
                <Column title="Plan" dataIndex="plan" key="plan" />
                <Column title="Amount" dataIndex="amount" key="amount" />
                <Column
                  title="Status"
                  dataIndex="status"
                  key="status"
                  render={(status) => (
                    <span
                      style={{
                        backgroundColor:
                          status === "Complete"
                            ? "rgba(25, 212, 66, 0.18)"
                            : "rgba(181, 14, 14, 0.18)",
                        borderRadius: "27px",
                        fontWeight: "500",
                        padding: "5px 10px",
                      }}
                    >
                      {status}
                    </span>
                  )}
                />
                <Column
                  title="Subscription"
                  dataIndex="subscription"
                  key="subscription"
                />

                <Column title="End Date" dataIndex="endDate" key="endDate" />
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Bill;
