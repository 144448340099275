import React, { useState, useEffect } from "react";
import { Space, Table, Spin } from "antd"; 
import { useNavigate } from "react-router-dom";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import arrowBlack from "../../assets/icons/arrow-black.svg";
import searchIcon from "../../assets/icons/search.svg";
import redTrash from "../../assets/icons/redTrash.svg";
import Swal from "sweetalert2";
import verifyAdmin from "../../middleware/verifyAdmin";
import ViewFeedback from "../modals/viewFeedback";
import axios from "axios";
import DelCardModal from "../modals/delCardModal";
import { Icon } from "@iconify/react/dist/iconify.js";

const { Column } = Table;

const Feedback = () => {
  const navigate = useNavigate();
  const [isFeedbackModal, setIsFeedbackModal] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [feedbacks, setFeedbacks] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFeedbackId, setSelectedFeedbackId] = useState(null); 
  const [loading, setLoading] = useState(true); 

  const showViewFeedModal = (id) => {
    setSelectedFeedbackId(id); 
    setIsFeedbackModal(true);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const fetchAllFeedback = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/admin-route/getfeedback`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data && response.data.data) {
        const formattedFeedback = response.data.data.map((feedback, index) => ({
          key: index.toString(),
          feedbackId: feedback._id,
          userName: feedback.userName,
          feedback: feedback.feedback,
          status: feedback.status,
          date: formatDate(feedback.createdAt),
          actions: ["delete", "user"]
        }));
        setFeedbacks(formattedFeedback);
      } else {
        console.error("Data not found in response. Response structure:", response.data);
      }
    } catch (error) {
      if (error.response) {
        console.error("Error Response Headers:", error.response.headers);
      } else if (error.request) {
        console.error("Error Request:", error.request);
      } else {
        console.error("Error Message:", error.message);
      }
    } finally {
      setLoading(false); 
    }
  };

  const updateFeedbackList = (updatedFeedback) => {
    setFeedbacks((prevFeedbacks) =>
      prevFeedbacks.map((feedback) =>
        feedback.feedbackId === updatedFeedback.feedbackId ? updatedFeedback : feedback
      )
    );
  };

  useEffect(() => {
    const checkAdmin = async () => {
      const result = await verifyAdmin();
      if (!result) {
        Swal.fire({
          icon: 'error',
          title: 'Access Denied',
          text: 'Only admins can access this page.',
          confirmButtonText: 'OK'
        }).then(() => {
          navigate('/settings/profile');
        });
      } else {
        setIsAdmin(true);
        fetchAllFeedback();
      }
    };
    checkAdmin();
  }, [navigate]);

  const showDelModal = async (id) => {
    const token = localStorage.getItem("token");
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`${process.env.REACT_APP_BASE_URL}/admin-route/deletefeedback/${id}`, {
            headers: { Authorization: `Bearer ${token}` },
          });

          Swal.fire(
            'Deleted!',
            'The feedback has been deleted.',
            'success'
          );

          fetchAllFeedback();
        } catch (error) {
          Swal.fire(
            'Error!',
            'An error occurred while deleting the feedback.',
            'error'
          );
          console.error(error);
        }
      }
    });
  };

  if (!isAdmin) {
    return null; 
  }

  return (
    <>
      <div className="userManagementWrapper">
        <div className="user-header d-flex gap-4">
          <img
            className="cursor-pointer"
            onClick={() => {
              navigate("/settings/profile");
            }}
            src={arrowBlack}
            alt=""
          />
          <h4 className="field-text fw-bold">Feedback & Suggestions</h4>
        </div>
        <div className="userContent">
          <div className="tableWrapper">
            {loading ? ( 
              <div className="loading-indicator">
                <Spin size="large" />
              </div>
            ) : (
              <Table
                dataSource={feedbacks}
                pagination={{
                  pageSize: 6,
                  showSizeChanger: false,
                  itemRender: (page, type, originalElement) => {
                    if (type === "prev") {
                      return <LeftOutlined />;
                    }
                    if (type === "next") {
                      return <RightOutlined />;
                    }
                    if (page > 5) {
                      return <span>...</span>;
                    }
                    return originalElement;
                  },
                  position: ["bottomCenter"],
                }}
              >
                <Column
                  title="Feedback Id"
                  dataIndex="feedbackId"
                  key="feedbackId"
                />
                <Column title="User Name" dataIndex="userName" key="userName" />
                <Column title="Feedback" dataIndex="feedback" key="feedback" />
                <Column title="Date Submitted" dataIndex="date" key="date" />
                <Column title="Status" dataIndex="status" key="status" />
                <Column
                  title="Actions"
                  dataIndex="actions"
                  key="actions"
                  render={(actions, record) => (
                    <Space size="large">
                      {actions.includes("delete") && (
                        <img
                          src={redTrash}
                          alt="Delete"
                          style={{ cursor: "pointer" }}
                          onClick={() => showDelModal(record.feedbackId)}
                        />
                      )}
                      {actions.includes("user") && (
                        <Icon
                          onClick={() => showViewFeedModal(record.feedbackId)} 
                          className="cursor-pointer"
                          icon="ph:eye-bold"
                          width="18"
                          height="18"
                          style={{ color: "black", marginLeft: "30px" }}
                        />
                      )}
                    </Space>
                  )}
                />
              </Table>
            )}
          </div>
        </div>
      </div>

      <ViewFeedback
        isFeedbackModal={isFeedbackModal}
        setIsFeedbackModal={setIsFeedbackModal}
        feedbackId={selectedFeedbackId} 
        updateFeedbackList={updateFeedbackList} 
      />
      <DelCardModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </>
  );
};

export default Feedback;
