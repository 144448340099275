import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Input from "../../../shared/input";
import Buttoon from "../../../components/buttoon";
import { Modal } from "antd";
import PricingUpgrade from "../../../../src/components/pricing/pricingUpgarde";

const Profile = ({ setActive }) => {
  // MODAL
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [User , setUser] = useState(null);
  
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    setActive(value);
  };

  const navigate = useNavigate();
  const [formFields, setFormFields] = useState({
    first_name: "",
    last_name: "",
    company_name: "",
    company_website: "",
    department: "",
    about: "",
  });

  const [errors, setErrors] = useState({});

  const validate = () => {
    const errors = {};
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + 
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + 
      "((\\d{1,3}\\.){3}\\d{1,3}))" + 
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + 
      "(\\?[;&a-z\\d%_.~+=-]*)?" + 
      "(\\#[-a-z\\d_]*)?$", "i" 
    );

    if (!formFields.first_name) {
      errors.first_name = "First Name is required";
    }

    if (!formFields.last_name) {
      errors.last_name = "Last Name is required";
    }

    if (!formFields.company_name) {
      errors.company_name = "Company Name is required";
    }

    if (!formFields.company_website) {
      errors.company_website = "Company Website is required";
    } else if (!urlPattern.test(formFields.company_website)) {
      errors.company_website = "Company Website is not valid";
    }

    if (!formFields.department) {
      errors.department = "Department is required";
    }

    if (!formFields.about) {
      errors.about = "Description is required";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormFields({ ...formFields, [name]: value });
  };

  useEffect(() =>{
    const fetchLoginUser = async () => {
      const token = localStorage.getItem("token");
      try {
      const responce =  await axios.get(`${process.env.REACT_APP_BASE_URL}/fetch_login_user`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
         console.log("Login User Fetch Successfully ");
         setUser(responce.data.data)
      } catch (error) {
        console.error("Error Fetch the  Login Profile:", error);
      }
   
    }
  
    fetchLoginUser();
   },[setUser]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const token = localStorage.getItem("token");
      try {
        await axios.put(
          `${process.env.REACT_APP_BASE_URL}/profile_update`,
          formFields,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        Swal.fire({
          title: "Success",
          text: "Your profile has been updated successfully!",
          icon: "success",
          confirmButtonText: "OK",
        });
        setFormFields({
          first_name: "",
          last_name: "",
          company_name: "",
          company_website: "",
          department: "",
          about: "",
        });        console.log("Profile update successful");
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: "Error updating profile",
          icon: "error",
          confirmButtonText: "OK",
        });
        console.error("Error updating profile:", error);
      }
    } else {
      Swal.fire({
        title: "Error",
        text: "Please fix the errors in the form",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <>
      <div className="profileContent">
        <div className="billingDiv d-flex flex-column justify-content-left gap-2">
          <p className="fw-semibold">Billing</p>
          <p className="fw-medium">
            You are on a trial on ChatKYC &nbsp;
            <span onClick={showModal} className="text-primary cursor-pointer">
              Upgrade Now.{" "}
            </span>
          </p>
        </div>

        <div className="billingDiv d-flex flex-column justify-content-left gap-2">
          <p className="fw-semibold">Profile</p>
          <p className="fw-medium">
            Setting up your profile will personalize your experience with
            ChatKYC.
          </p>
        </div>
        <form className="w-100" onSubmit={handleSubmit}>
          <div className="nameDiv d-flex justify-content-between w-100">
            <div className="fnameContainer d-flex flex-column">
              <label className="fw-bold mb-2" htmlFor="first_name">
                First Name
              </label>
              <Input
                wrapperClass="rounded"
                inputClass="fw-medium text-black"
                value={formFields.first_name}
                height="46px"
                type="text"
                className="ftrEmail"
                width="100"
                name="first_name"
                onChange={handleInputChange}
              />
              {errors.first_name && (
                <p className="error-message">{errors.first_name}</p>
              )}
            </div>

            <div className="fnameContainer d-flex flex-column">
              <label className="fw-bold mb-2" htmlFor="last_name">
                Last Name
              </label>
              <Input
                wrapperClass="rounded"
                inputClass="fw-medium text-black"
                value={formFields.last_name}
                height="46px"
                type="text"
                className="ftrEmail"
                width="100%"
                name="last_name"
                onChange={handleInputChange}
              />
              {errors.last_name && (
                <p className="error-message">{errors.last_name}</p>
              )}
            </div>
          </div>

          <div className="nameDiv d-flex justify-content-between w-100 mt-4">
            <div className="fnameContainer d-flex flex-column">
              <label className="fw-bold mb-2" htmlFor="company_name">
                Company Name
              </label>
              <Input
                wrapperClass="rounded"
                inputClass="fw-medium text-black"
                value={formFields.company_name}
                height="46px"
                type="text"
                className="ftrEmail"
                width="100"
                name="company_name"
                onChange={handleInputChange}
              />
              {errors.company_name && (
                <p className="error-message">{errors.company_name}</p>
              )}
            </div>

            <div className="fnameContainer d-flex flex-column">
              <label className="fw-bold mb-2" htmlFor="company_website">
                Company Website
              </label>
              <Input
                inputClass="fw-medium text-black"
                value={formFields.company_website}
                height="46px"
                type="text"
                className="ftrEmail"
                width="100%"
                name="company_website"
                onChange={handleInputChange}
              />
              {errors.company_website && (
                <p className="error-message">{errors.company_website}</p>
              )}
            </div>
          </div>

          <div className="nameDiv d-flex justify-content-between w-100 mt-4">
            <div className="fnameContainer d-flex flex-column">
              <label className="fw-bold mb-2" htmlFor="department">
                Role
              </label>
              <Input
                wrapperClass="rounded"
                inputClass="fw-medium text-black"
                value={formFields.department}
                height="46px"
                type="text"
                className="ftrEmail"
                width="100"
                name="department"
                onChange={handleInputChange}
              />
              {errors.department && (
                <p className="error-message">{errors.department}</p>
              )}
            </div>

            <div className="fnameContainer d-flex flex-column"></div>
          </div>

          <div className="fnameContainer d-flex flex-column mt-4">
            <label className="fw-bold mb-2" htmlFor="about">
              About You
            </label>
            <Input
              wrapperClass="rounded"
              inputClass="fw-medium text-black"
              value={formFields.about}
              minHeight={93}
              type="text"
              className="ftrEmail saveProfileBtn"
              name="about"
              onChange={handleInputChange}
            />
            {errors.about && <p className="error-message">{errors.about}</p>}
          </div>
          <div className="d-flex justify-center">
            <Buttoon
              className="btn btn-primary m-auto mt-4"
              minWidth={216}
              minHeight={43}
              text="Save Profile"
            ></Buttoon>
          </div>
        </form>
      </div>

      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={837}
        className="upgrade-plans"
      >
        <div className="upgrade-content">
          <div className="upgardeHeader">
            <h4 className="fw-bold mt-3">Upgrade Plans</h4>
            <div className="d-flex justify-content-between upgradePriceContent">
              <p className="fw-bold">
                You get unlimited access to ChatKYC with both plans. Cancel
                anytime
              </p>
              <p className="upgrade-text">
                Save <span className="primary"> 30% </span> Annually.
              </p>
              <select
                id="options"
                value={selectedOption}
                onChange={handleChange}
                className="upgrade-select"
              >
                <option value="annually" onClick={() => setActive("Annually")}>
                  Annually
                </option>
                <option value="monthly" onClick={() => setActive("Monthly")}>
                  Monthly
                </option>
              </select>
            </div>
          </div>
        </div>

        <hr />
        <PricingUpgrade />
      </Modal>
    </>
  );
};

export default Profile;
