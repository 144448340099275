import { Icon } from "@iconify/react";
import React, { useState, useEffect, useRef } from "react";
import threedots from "../assets/icons/3dots.svg";
import DelCardModal from "../dashboard/modals/delCardModal";
const ThreeDots = () => {
  const dropRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropRef.current && !dropRef.current.contains(event.target)) {
      setShowPopup(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const [showPopup, setShowPopup] = useState(false);

  const handletoggle = () => {
    setShowPopup(!showPopup);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showDelModal = () => {
    setIsModalOpen(true);
  };
  return (
    <>
      <div className="rightArea" ref={dropRef}>
        <img
          src={threedots}
          alt=""
          className="cursor-pointer"
          onClick={handletoggle}
        />
        {showPopup && (
          <>
            <div className="editDiv position-absolute">
              <li className="cursor-pointer v-center justify-content-left gap-2 mb-3">
                <Icon
                  icon="la:pen"
                  width="20"
                  height="20"
                  style={{ color: "black" }}
                />
                <span className="cursor-pointer text-small fw-medium">
                  Rename
                </span>
              </li>
              <li
                className=" cursor-pointer v-center justify-content-left gap-2"
                onClick={showDelModal}
              >
                <Icon
                  icon="ph:trash"
                  width="19"
                  height="19"
                  style={{ color: "red" }}
                />
                <span className="cursor-pointer text-danger text-small fw-medium">
                  Delete
                </span>
              </li>
            </div>
          </>
        )}
      </div>
      <DelCardModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </>
  );
};

export default ThreeDots;
