import React, { useState } from "react";
import promptClip from "../assets/icons/promptClip.svg";
import paperPlane from "../assets/icons/paperPlain.svg";
import uploadIcon from "../assets/icons/uploadCloud.svg";
import { message, Modal, Progress, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";


// const DashboardChatArea = () => {
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const props = {
//     name: "file",
//     multiple: true,
//     action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
//     headers: {
//       authorization: "authorization-text", // Add authorization if needed
//     },
//     onChange(info) {
//       const { status } = info.file;
//       if (status !== "uploading") {
//         console.log("File List:", info.fileList);
//       }
//       if (status === "done") {
//         message.success(`${info.file.name} file uploaded successfully.`);
//       } else if (status === "error") {
//         message.error(`${info.file.name} file upload failed.`);
//       }
//     },
//     onDrop(e) {
//       console.log("Dropped files:", e.dataTransfer.files);
//     },
//     onError(err) {
//       console.error("Upload error:", err);
//       message.error(`File upload failed: ${err.message}`);
//     },
//   };

//   const showModal = () => {
//     setIsModalOpen(true);
//   };

//   const handleOk = () => {
//     setIsModalOpen(false);
//   };

//   const handleCancel = () => {
//     setIsModalOpen(false);
//   };

//   return (
//     <>
//       <div className="promptDiv v-center justify-content-between">
//         <div className="promptArea1 h-center v-center">
//           <label htmlFor="">
//             <img
//               className="cursor-pointer"
//               src={promptClip}
//               alt="Upload"
//               onClick={showModal}
//             />
//           </label>
//         </div>
//         <div className="promptArea2">
//           <input
//             className="text-large fw-medium w-100"
//             type="text"
//             placeholder="Write to ask"
//           />
//         </div>
//         <div className="promptArea3 h-center v-center">
//           <img className="cursor-pointer" src={paperPlane} alt="Send" />
//         </div>
//       </div>

//       <Modal
//         open={isModalOpen}
//         onOk={handleOk}
//         onCancel={handleCancel}
//         footer={null}
//         centered
//         width={666}
//         className="file-upload-modal"
//       >
//         <div className="uploadboxwrap p-5">
//           <h4 className="fw-bold mb-3">Upload a File</h4>
//           <Dragger {...props}>
//             <div className="d-flex flex-column justify-content-center align-items-center">
//               <img src={uploadIcon} alt="Upload" />
//               <h5>Select file to upload</h5>
//               <p>Or drag & drop here</p>
//             </div>
//           </Dragger>
//         </div>
//       </Modal>
//     </>
//   );
// };
const DashboardChatArea = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadTimeout, setUploadTimeout] = useState(null);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    resetUpload();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    resetUpload();
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setSelectedFile(file);
      message.success(`${file.name} file selected.`);
      simulateUpload(file);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      setSelectedFile(file);
      message.success(`${file.name} file selected.`);
      simulateUpload(file);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const simulateUpload = (file) => {
    setUploading(true);
    setProgress(0);
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          setUploading(false);
          message.success(`${file.name} file uploaded successfully.`);
          return 100;
        }
        return prevProgress + 10;
      });
    }, 300);
    setUploadTimeout(interval);
  };

  const handleCancelUpload = () => {
    if (uploadTimeout) {
      clearInterval(uploadTimeout);
      setUploading(false);
      setProgress(0);
      setSelectedFile(null);
      message.warning(`File upload cancelled.`);
    }
  };

  const resetUpload = () => {
    setSelectedFile(null);
    setProgress(0);
    setUploading(false);
  };

  return (
    <>
      <div className="promptDiv v-center justify-content-between">
        <div className="promptArea1 h-center v-center">
          <label htmlFor="">
            <img
              className="cursor-pointer"
              src={promptClip}
              alt="Upload"
              onClick={showModal}
            />
          </label>
        </div>
        <div className="promptArea2">
          <input
            className="text-med fw-normal w-100"
            type="text"
            placeholder="Message ChatKYC"
          />
        </div>
        <div className="promptArea3 h-center v-center">
          <img className="cursor-pointer" src={paperPlane} alt="Send" />
        </div>
      </div>

      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        centered
        width={666}
        className="file-upload-modal"
      >
        <div className="uploadboxwrap">
          <h4 className="fw-bold mb-3">Upload a File</h4>
          <div
            className="upload-file-div v-center flex-column"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onClick={() => document.getElementById("fileInput").click()}
            style={{ cursor: "pointer", border: "3px dashed #0085FF", borderRadius: "10px", padding: "26px" }}
          >
            <img src={uploadIcon} alt="Upload" />
            <h5 className="fw-bold mt-1">Select file to upload</h5>
            <p className="fw-semibold">Or drag & drop here</p>
            <input
              id="fileInput"
              type="file"
              accept=".pdf,.csv,.pptx,.docx,.txt"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            {selectedFile && (
              <p className="small text-black mt-2">
                Selected file: {selectedFile.name}
              </p>
            )}
          </div>
          {(uploading || progress === 100) && (
            <div className="upload-progress-div mt-3">
              <div className="d-flex align-items-center ">
                <Progress className="" percent={progress} style={{ flexGrow: 1}} strokeColor="#0085FF"  />
                <CloseOutlined onClick={handleCancelUpload} style={{ cursor: "pointer", marginLeft: "10px" }} />
              </div>
            </div>
          )}
          {/* {progress === 100 && (
            <div className="d-flex justify-content-center mt-3">
              <Button type="primary" onClick={resetUpload}>Upload Another File</Button>
            </div>
          )} */}
        </div>
      </Modal>
    </>
  );
};


export default DashboardChatArea;
