import React, { useState } from "react";
import { Link, useNavigate, NavLink, useLocation } from "react-router-dom";
import { Icon } from "@iconify/react";
import { Drawer, Modal } from "antd";
import finalLogo from "../assets/icons/kycLogo.svg";
import Buttoon from "./buttoon";
import btnIcon from "../assets/icons/btnIcon.svg";
import ThreeDots from "../shared/threeDots";
import PricingUpgrade from "./pricing/pricingUpgarde";

const Sidebar = ({ isDrawerOpen, toggleDrawer, active, setActive }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPopup, setShowPopup] = useState(false);

  const handletoggle = () => {
    setShowPopup(!showPopup);
  };

  // MODAL
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    setActive(value);
  };

  const handleLinkClick = (path) => {
    navigate(path);
    toggleDrawer();
  };

  return (
    <>
      {/* Desktop Sidebar */}
      <div className="sidebar-main-container d-flex flex-column">
        <div className="scrollable">
          <div className="sidebar-logo-container d-flex justify-content-start w-100">
            <img src={finalLogo} alt="logo" className="logo" />
          </div>

          <Buttoon
            // className={`btn btn-primary w-100 newChatIcon d-flex v-center ${
            //   location.pathname === "/settings/profile" ? "disabled" : ""
            // }`}
            className="btn btn-primary w-100 newChatIcon d-flex v-center "
            minWidth={208}
            minHeight={39}
            img={btnIcon}
            text="New Chat"
            imgClass="newChatIcon"
            // disabled={location.pathname === "/settings/profile"}
            onClick={() => navigate("/dashboard")}
          />

          <div
            onClick={showModal}
            className="d-flex flex-column gap-1 pricePopup mt-3 cursor-pointer"
          >
            <p style={{ fontSize: "12px" }} className="">
              You have used 3 of 3 free chats.
            </p>
            <div className="coloredDiv"></div>
            <p style={{ fontSize: "12px" }} className="text-primary">
              Upgrade for less than $7 / month
            </p>
          </div>
          <div className="sidebar-options-container d-flex flex-column space-between">
            <div>
              <div className="myChatBox">
                <p className="text-small fw-bold text-left">My Chats</p>
                <hr />
                <p className="todayClr text-left text-small fw-normal my-2">
                  Today's
                </p>
                <div className="mainList d-flex justify-content-between v-center">
                  <div className="leftArea d-flex gap-2 ">
                    <NavLink
                      to="/dashboard-chat"
                      className="text-black d-flex gap-2"
                    >
                      <Icon
                        icon="circum:chat-2"
                        width="20"
                        height="20"
                        style={{ color: "gray" }}
                        className="sideIconss"
                      />

                      <span className="text-small fw-medium"> ABC Risks</span>
                    </NavLink>
                  </div>
                  <ThreeDots />
                </div>

                <p className="todayClr text-left text-small fw-normal my-2">
                  Yesterday's
                </p>
                <div className="mainList d-flex justify-content-between v-center">
                  <div className="leftArea d-flex gap-2 ">
                    <NavLink
                      to="/dashboard-chat2"
                      className="text-black d-flex gap-2"
                    >
                      <Icon
                        icon="circum:chat-2"
                        width="20"
                        height="20"
                        style={{ color: "gray" }}
                        className="sideIconss"
                      />

                      <span className="text-small fw-medium">AML Policy</span>
                    </NavLink>
                  </div>
                  <ThreeDots />
                </div>
              </div>

              <div className="myChatBox">
                <p className="text-small fw-bold text-left">My Files</p>
                <hr />
                <p className="todayClr text-left text-small fw-normal my-2">
                  Today's
                </p>
                <div className="mainList d-flex justify-content-between v-center">
                  <NavLink to="/aml-policy" className="text-black">
                    <div className="leftArea d-flex gap-2 ">
                      <Icon
                        icon="mdi:paper-edit-outline"
                        width="20"
                        height="20"
                        style={{ color: "gray" }}
                        className="sideIconss"
                      />
                      <span className="text-small fw-medium">PEP Policy</span>
                    </div>
                  </NavLink>

                  <ThreeDots />
                </div>

                <p className="todayClr text-left text-small fw-normal my-2">
                  Yesterday's
                </p>
                <div className="mainList d-flex justify-content-between v-center">
                  <NavLink to="/sanctionCheck" className="text-black">
                    <div className="leftArea d-flex gap-2 ">
                      <Icon
                        icon="mdi:paper-edit-outline"
                        width="20"
                        height="20"
                        style={{ color: "gray" }}
                        className="sideIconss"
                      />
                      <span className="text-small fw-medium">
                        Sanctions Check
                      </span>
                    </div>
                  </NavLink>
                  <ThreeDots />
                </div>

                <div className="mainList d-flex justify-content-between v-center mt-2">
                  <NavLink to="/fccTest" className="text-black">
                    <div className="leftArea d-flex gap-2 ">
                      <Icon
                        icon="mdi:paper-edit-outline"
                        width="20"
                        height="20"
                        style={{ color: "gray" }}
                        className="sideIconss"
                      />
                      <span className="text-small fw-medium">FCC Testing</span>
                    </div>
                  </NavLink>
                  <ThreeDots />
                </div>
              </div>
            </div>

            <NavLink className="accLinkColr  text-black" to="/settings/profile">
              <div className="accSetting py-3">
                <div className="v-center  gap-2 bottom-link">
                  <Icon
                    icon="ion:settings-outline"
                    width="24"
                    height="24"
                    style={{ color: "#0085FF" }}
                    className="accIconn"
                  />
                  <span className="accText fw-bold text-small">
                    Account Settings
                  </span>
                </div>
              </div>
            </NavLink>
          </div>
        </div>
      </div>

      {/* Mobile sidebar */}
      <div className="mobile-sidebar">
        <Drawer
          placement="left"
          closable={false}
          onClose={toggleDrawer}
          open={isDrawerOpen}
          width={350}
          className="mobile-menu-drawer"
        >
          <div className="sidebar-container d-flex flex-column">
            <div className="scrollable">
              <div className="sidebar-logo-container mb-2">
                <Link to="#">
                  <img src={finalLogo} alt="logo" className="logo" />
                </Link>
              </div>

              <Buttoon
                className="btn btn-primary w-100 newChatIcon d-flex v-center"
                minWidth={208}
                minHeight={39}
                img={btnIcon}
                text="New Chat"
                imgClass="newChatIcon"
                // disabled={location.pathname === "/settings/profile"}
                onClick={() => navigate("/dashboard")}
              ></Buttoon>

              <div
                onClick={showModal}
                className="d-flex flex-column gap-1 pricePopup my-3 cursor-pointer"
              >
                <p style={{ fontSize: "12px" }} className="">
                  You have used 3 of 3 free chats.
                </p>
                <div
                  style={{
                    width: "100%",
                    height: "8px",
                    backgroundImage:
                      "linear-gradient(92deg, #009EFF 0.8%, #9360FF 98.13%)",
                    borderRadius: "5px",
                  }}
                  className="coloredDiv"
                ></div>
                <p style={{ fontSize: "12px" }} className="text-primary">
                  Upgrade for less than $7 / month
                </p>
              </div>
              <div className="sidebar-options-container">
                <div className="myChatBox">
                  <p className="text-small fw-bold text-left">My Chats</p>
                  <hr />
                  <p className="todayClr text-left text-small fw-normal my-2">
                    Today's
                  </p>
                  <div className="mainListArea d-flex justify-content-between v-center">
                    <div className="leftArea d-flex gap-2 ">
                      <Icon
                        icon="circum:chat-2"
                        width="20"
                        height="20"
                        style={{ color: "gray" }}
                      />
                      <Link
                        to="/dashboard-chat"
                        className="text-black"
                        onClick={toggleDrawer}
                      >
                        <span className="text-small fw-medium"> ABC Risks</span>
                      </Link>
                    </div>
                    <ThreeDots />
                  </div>

                  <p className="todayClr text-left text-small fw-normal my-2">
                    Yesterday's
                  </p>
                  <div className="mainListArea d-flex justify-content-between v-center">
                    <div className="leftArea d-flex gap-2 ">
                      <Icon
                        icon="circum:chat-2"
                        width="20"
                        height="20"
                        style={{ color: "gray" }}
                      />
                      <Link
                        to="/dashboard-chat"
                        className="text-black"
                        onClick={toggleDrawer}
                      >
                        <span className="text-small fw-medium">AML Policy</span>
                      </Link>
                    </div>
                    <ThreeDots />
                  </div>
                </div>

                <div className="myChatBox mt-3">
                  <p className="text-small fw-bold text-left">My Files</p>
                  <hr />
                  <p className="todayClr text-left text-small fw-normal my-2">
                    Today's
                  </p>
                  <div className="mainListArea d-flex justify-content-between v-center">
                    <Link
                      to="/aml-policy"
                      className="text-black"
                      onClick={toggleDrawer}
                    >
                      <div className="leftArea d-flex gap-2 ">
                        <Icon
                          icon="mdi:paper-edit-outline"
                          width="20"
                          height="20"
                          style={{ color: "gray" }}
                        />
                        <span className="text-small fw-medium">PEP Policy</span>
                      </div>
                    </Link>
                    <ThreeDots />
                  </div>

                  <p className="todayClr text-left text-small fw-normal my-2">
                    Yesterday's
                  </p>
                  <div className="mainListArea d-flex justify-content-between v-center">
                    <Link
                      to="/aml-policy"
                      className="text-black"
                      onClick={toggleDrawer}
                    >
                      <div className="leftArea d-flex gap-2 ">
                        <Icon
                          icon="mdi:paper-edit-outline"
                          width="20"
                          height="20"
                          style={{ color: "gray" }}
                        />
                        <span className="text-small fw-medium">
                          Sanctions Check
                        </span>
                      </div>
                    </Link>
                    <ThreeDots />
                  </div>

                  <div className="mainListArea d-flex justify-content-between v-center mt-2">
                    <Link
                      to="/aml-policy"
                      className="text-black"
                      onClick={toggleDrawer}
                    >
                      <div className="leftArea d-flex gap-2 ">
                        <Icon
                          icon="mdi:paper-edit-outline"
                          width="20"
                          height="20"
                          style={{ color: "gray" }}
                        />
                        <span className="text-small fw-medium">
                          FCC Testing
                        </span>
                      </div>
                    </Link>
                    <ThreeDots />
                  </div>
                </div>
              </div>
            </div>
            <Link to="/settings/profile" onClick={toggleDrawer}>
              <div className="accSetting py-3">
                <div className="d-flex gap-2 ">
                  <Icon
                    icon="ion:settings-outline"
                    width="24"
                    height="24"
                    style={{ color: "#0085FF" }}
                  />
                  <span className="fw-bold text-small text-black">
                    Account Settings
                  </span>
                </div>
              </div>
            </Link>
          </div>
        </Drawer>
      </div>

      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={837}
        className="upgrade-plans"
      >
        <div className="upgrade-content">
          <div className="upgardeHeader">
            <h4 className="fw-bold mt-3">Upgarde Plans</h4>
            <div className="d-flex  gap-4 upgradePriceContent">
              {" "}
              <p className="fw-bold">
                You get unlimited access to ChatKYC with both plans. Cancel
                anytime
              </p>
              <p className="upgrade-text">
                Save <span className="primary"> 30% </span> Annually
              </p>
              <select
                id="options"
                value={selectedOption}
                onChange={handleChange}
                className="upgrade-select fw-bold"
              >
                <option value="annually" className="fw-bold" onClick={() => setActive("Annually")}>
                  Annually
                </option>
                <option value="monthly" onClick={() => setActive("Monthly")}>
                  Monthly
                </option>
              </select>
            </div>
          </div>
        </div>

        <hr />
        <PricingUpgrade />
      </Modal>
    </>
  );
};

export default Sidebar;



