import React, { useState } from "react";
import PricingCard from "../../shared/pricingCard";
import PricingHeader from "./pricingHeader";

const PricingCardItem = () => {
  const [active, setActive] = useState("Monthly");

  const CardData = [
    {
      id: 1,
      dollarYear: 0,
      dollarMonth: 0,
      badge: "Try ChatKYC Free",
      year: "",
      month: "",
      features: [
        "3 Chats",
        "All Basic + Pro Features",
        "Basic Support",
        "Don't keep ChatKYC alive",
      ],
    },
    {
      id: 2,
      dollarYear: 39,
      dollarMonth: 39,
      badge: "Basic",
      year: "/Year",
      month: "/Monthly",
      priceId:process.env.REACT_APP_BASIC_PLAN_PRICE,
      features: [
        "Unlimited chats",
        "Customized to your profile & role",
        "AI-generated KYC drafts",
        "Coaching on writing & PM skills",
        "Keep ChatKYC Alive",
      ],
    },
    {
      id: 3,
      dollarYear: 100,
      dollarMonth: 109,
      badge: "Pro",
      year: "/Year",
      month: "/Monthly",
      priceId:process.env.REACT_APP_PRO_PLAN_PRICE,
      features: [
        "Everything in basic plan",
        "Save & export docs from chat",
        "Bring-your-own KYC template",
        "Priority input to roadmap",
        "Weekly feature releases",
        "VIP support from @clairevo",
        "Faster response times",
        "Keep ChatKYC Alive",
      ],
    },
  ];

  return (
    <>
      <PricingHeader active={active} setActive={setActive} />
      <div className="pricingCardsContainer flex-wrap justify-content-center gap-5">
        {CardData.map((card) => (
          <PricingCard
            key={card.id}
            active={active}
            dollarYear={card.dollarYear}
            dollarMonth={card.dollarMonth}
            badge={card.badge}
            year={card.year}
            priceId={card.priceId}
            month={card.month}
            features={card.features}
          />
        ))}
      </div>
    </>
  );
};

export default PricingCardItem;