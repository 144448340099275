import React, { useState } from "react";
import colordContract from "../assets/icons/coloredContarct.svg";
import questionMark from "../assets/icons/question-mark.svg";
import uploadd from "../assets/icons/upload.svg";
import folder from "../assets/icons/folder.svg";
import promptClip from "../assets/icons/promptClip.svg";
import paperPlane from "../assets/icons/paperPlain.svg";
import chatBot from "../assets/icons/chotBotIcon.svg";
import msgSendIcon from "../assets/icons/msgSendIcon.svg";
import { message, Modal, Upload } from "antd";
import uploadIcon from "../assets/icons/uploadCloud.svg";
import { Icon } from "@iconify/react";
import DashboardChatArea from "../components/messagebox";
const DashboardMain = () => {
  const [showPopup, setShowPopup] = useState(false);

  const handletoggle = () => {
    setShowPopup(!showPopup);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { Dragger } = Upload;
  const props = {
    name: "file",
    multiple: true,
    action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <div className="dashboardMainContainer d-flex justify-content-between position-relative ">
        <div className="welcomekycDiv  flex-column gap-2 h-center v-center">
          <h2 className="fw-bold">Welcome to ChatKYC</h2>
          {/* <p className="fw-semibold mt-1">Get started by KYC a task & chat can do the rest. Not sure where to start?</p> */}

          {/* Predefined Messages */}
          <div className="customAMLDiv w-100    mt-5">
            <div className="card1 v-center h-center gap-3 cursor-pointer">
              <img src={colordContract} alt="" />
              <p className="fw-medium text-large">Help me improve a policy</p>
              <Icon
                icon="ic:round-keyboard-arrow-right"
                width="28"
                height="28"
                style={{ color: "black" }}
              />
            </div>
            <div className="card1 v-center h-center gap-3 cursor-pointer">
              <img src={questionMark} alt="" />
              <p className="fw-medium text-large">
              Summarise a regulatory text
              </p>
              <Icon
                icon="ic:round-keyboard-arrow-right"
                width="28"
                height="28"
                style={{ color: "black" }}
              />
            </div>

            <div className="card1 v-center h-center gap-3 cursor-pointer">
              <img src={uploadd} alt="" />
              <p className="fw-medium text-large">
                Upload and review your compliance document.
              </p>
              <Icon
                icon="ic:round-keyboard-arrow-right"
                  width="40"
                height="40"
                style={{ color: "black" }}
              />
            </div>

            <div className="card1  v-center h-center gap-3 cursor-pointer">
              <img src={folder} alt="" />
              <p className="fw-medium text-large">
                Browse and use compliance templates.
              </p>
              <Icon
                icon="ic:round-keyboard-arrow-right"
                 width="40"
                height="40"
                style={{ color: "black" }}
              />
            </div>
          </div>
        </div>
        <DashboardChatArea />
      </div>

      <Modal
        title={<h4>Upload File</h4>}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        centered
        width={666}
        height={318}
        className="file-upload-modal"
      >
        <div className="uploadboxwrap">
          <Dragger {...props}>
            <div className=" d-flex flex-column justify-content-center align-items-center">
              <img src={uploadIcon} alt="Upload" />

              <h5>Select file to upload</h5>
              <p>Or drag & drop here</p>
            </div>
          </Dragger>
        </div>
      </Modal>
    </>
  );
};

export default DashboardMain;
