import React, { useState, useEffect } from "react";
import { Space, Table } from "antd";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import arrowBlack from "../../assets/icons/arrow-black.svg";
import searchIcon from "../../assets/icons/search.svg";
import redTrash from "../../assets/icons/redTrash.svg";
import bluerUser from "../../assets/icons/blueUser.svg";
import ViewInfo from "../modals/viewInfo";
import DelCardModal from "../modals/delCardModal";
import verifyAdmin from "../../middleware/verifyAdmin";
import axios from "axios";

const { Column } = Table;

const UserManage = () => {
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);
  const [isInfoModal, setIsInfoModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [User, setUser] = useState([]);

  const showViewInfoModal = () => {
    setIsInfoModal(true);
  };

  const showDelModal = async (id) => {
    const token = localStorage.getItem("token");
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`${process.env.REACT_APP_BASE_URL}/delete/${id}`, {
            headers: { Authorization: `Bearer ${token}` },
          });

          Swal.fire(
            'Deleted!',
            'The user has been deleted.',
            'success'
          );

          fetchAllUsers();
        } catch (error) {
          Swal.fire(
            'Error!',
            'An error occurred while deleting the user.',
            'error'
          );
          console.error(error);
        }
      }
    });
  };

  const fetchAllUsers = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/admin-route/getuser`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data && response.data.data) {
        const formattedUsers = response.data.data.map((user, index) => ({
          key: index.toString(),
          userId: user._id,
          userName: user.username,
          email: user.email,
          status: user.status,
          subscript: user.subscript,
        }));
        setUser(formattedUsers);
      } else {
        console.error("Data not found in response. Response structure:", response.data);
      }
    } catch (error) {
      if (error.response) {
        console.error("Error Response Headers:", error.response.headers);
      } else if (error.request) {
        console.error("Error Request:", error.request);
      } else {
        console.error("Error Message:", error.message);
      }
    }
  };

  useEffect(() => {
    const checkAdmin = async () => {
      const result = await verifyAdmin();
      if (!result) {
        Swal.fire({
          icon: 'error',
          title: 'Access Denied',
          text: 'Only admins can access this page.',
          confirmButtonText: 'OK'
        }).then(() => {
          navigate('/settings/profile');
        });
      } else {
        setIsAdmin(true);
        fetchAllUsers();
      }
    };
    checkAdmin();
  }, [navigate]);

  if (!isAdmin) {
    return null;
  }

  return (
    <>
      <div className="userManagementWrapper">
        <div className="user-header d-flex gap-4">
          <img
            className="cursor-pointer"
            onClick={() => {
              navigate("/settings/profile");
            }}
            src={arrowBlack}
            alt=""
          />
          <h4 className="field-text fw-bold">User Management</h4>
        </div>
        <div className="userContent">
          <div className="userSearchField">
            <img src={searchIcon} alt="" />
            <input className="w-100 text-med" type="text" placeholder="Search by name" />
          </div>
          <div className="tableWrapper">
            <Table
              dataSource={User} 
              pagination={{
                pageSize: 6,
                showSizeChanger: false,
                itemRender: (page, type, originalElement) => {
                  if (type === "prev") {
                    return <LeftOutlined />;
                  }
                  if (type === "next") {
                    return <RightOutlined />;
                  }
                  if (page > 5) {
                    return <span>...</span>;
                  }
                  return originalElement;
                },
                position: ["bottomCenter"],
              }}
              style={{ marginTop: "20px" }}
            >
              <Column title="User ID" dataIndex="userId" key="userId" />
              <Column title="User Name" dataIndex="userName" key="userName" />
              <Column title="Email" dataIndex="email" key="email" />
              <Column
                title="Status"
                dataIndex="status"
                key="status"
                render={(status) => (
                  <span
                    style={{
                      backgroundColor: status === "Active" ? "rgba(25, 212, 66, 0.18)" : "rgba(181, 14, 14, 0.18)",
                      borderRadius: "27px",
                      fontWeight: "500",
                      padding: "5px 10px",
                    }}
                  >
                    {status}
                  </span>
                )}
              />
              <Column title="Subscription" dataIndex="subscript" key="subscript" />
              <Column
                title="Actions"
                key="actions"
                render={(text, record) => (
                  <Space size="middle">
                    <img
                      src={redTrash}
                      alt="Delete"
                      style={{ cursor: "pointer" }}
                      onClick={() => showDelModal(record.userId)}
                    />
                    <img
                      src={bluerUser}
                      alt="User"
                      style={{ cursor: "pointer" }}
                      onClick={showViewInfoModal}
                    />
                  </Space>
                )}
              />
            </Table>
          </div>
        </div>
      </div>

      <ViewInfo isInfoModal={isInfoModal} setIsInfoModal={setIsInfoModal} />
      <DelCardModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </>
  );
};

export default UserManage;
