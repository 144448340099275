
import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import Buttoon from "../components/buttoon";
import check from "../assets/icons/checkIcon.svg";

// Load your Stripe public key
const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_KEY);

const PricingCard = ({
  active,
  dollarYear,
  dollarMonth,
  badge,
  year,
  month,
  features,
  priceId,
}) => {

  const handleSubscribe = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/payment/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ priceId }),
      });

      const data = await response.json();
      
      console.log(data.sessionId)
      if (data.sessionId) {
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId: data.sessionId,
        });

        if (error) {
          console.error('Stripe Checkout Error:', error.message);
        }
      } else {
        console.error('Failed to get Stripe Checkout Session ID');
      }
    } catch (error) {
      console.error('Error during checkout:', error);
    }
  };

  return (
    <div className="pricingContainer">
      <div className="header">
        <div className="cardHeading">
          <h1>{active === "Monthly" ? `$${dollarMonth}` : `$${dollarYear}`}</h1>
          <p>{active === "Monthly" ? month : year}</p>
        </div>
        <button className="badge">{badge}</button>
      </div>
      <hr />
      <div className="cardBody">
        <div>
          <ul>
            {features && features.length > 0 ? (
              features.map((feature, index) => (
                <li key={index} className="listItems d-flex gap-3 v-center">
                  <img src={check} alt="Check Icon" className="chexkImage" />
                  {feature}
                </li>
              ))
            ) : (
              <li className="listItems">No features specified</li>
            )}
          </ul>
        </div>
        <div>
          <Buttoon
            className="btn subscribeBtn"
            text="Subscribe"
            onClick={handleSubscribe} 
          />
        </div>
      </div>
    </div>
  );
};

export default PricingCard;
