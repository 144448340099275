import React, { useState } from "react";
import { Button, Input, Modal } from "antd";
import Buttoon from "../../components/buttoon";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";

const DelCardModal = ({ isModalOpen, setIsModalOpen }) => {
    
    const navigate = useNavigate();
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
<>
<Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="del-modal"
        centered={true}
        // width="45%"
        footer={null}
      >
        <div className="modal-content v-center flex-column  gap-3 py-3">
        <Icon icon="ep:delete" width="50" height="50"  style={{color: "black"}} />
          <h6 className="text-red text-center">
            Are you sure you want to delete?
          </h6>
<div className="d-flex gap-3">
<div className="h-center">
              <Buttoon
                className="btn btn-primary mt-3 m-auto"
                minWidth={100}
                minHeight={43}
                text="OK"
                onClick={() => {
                  handleCancel();
                }}
              ></Buttoon>
            </div>
            <div className="h-center">
              <Buttoon
                className="btn btn-secondary gray-btn mt-3 m-auto"
                minWidth={100}
                minHeight={43}
                text="cancel"
           
                onClick={() => {
                  handleCancel();
                }}
              ></Buttoon>
            </div>
</div>
         
          {/* <div className="modal-buttons d-flex ">
            <button
              className="btn-border-black "
              onClick={() => {
                handleCancel();
              }}
            >
              <label className="btn-primary fw-medium text-black cursor-pointer">
                Delete
              </label>
            </button>
            <button onClick={handleCancel} className="btn-black">
              <label className="btn-secondartext-white fw-medium cursor-pointer">
                Cancel
              </label>
            </button>
          </div> */}
        </div>
      </Modal>
</>
  )
}

export default DelCardModal;